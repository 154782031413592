interface Props {
  price: number;
  unit: "만" | "억";
  decimal: number;
}

export default function formatPriceUnit({
  price,
  unit,
  decimal,
}: Props): number {
  enum Unit {
    "만" = 10000,
    "억" = 100000000,
  }

  if (price < 0) {
    return price;
  }
  if (price < 10000) {
    return price;
  }
  return Number((price / Unit[unit]).toFixed(decimal));
}
