import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  Tooltip as MuiTooltip,
  IconButton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import { IBuildingBlockUnit } from "../../graphql/types";
import LineBarChart from "./LineBarChart";
import TableBlock from "./TableBlock";
import type { HeadCells } from "./TableBlock/TableBlock";

interface Props {
  selectedBuildingBlockUnit: IBuildingBlockUnit;
}

export default function LandTaxAssessedPrices({
  selectedBuildingBlockUnit,
}: Props) {
  const theme = useTheme();
  const landTaxAssessedDataSets =
    selectedBuildingBlockUnit.building.land_tax_info;
  const chartDatasets = landTaxAssessedDataSets?.map(data => ({
    label: data.tax_year,
    data: data.tax_price,
  }));

  const headCells: HeadCells = [
    {
      id: "date",
      label: "기준연도",
      numeric: true,
    },
    {
      id: "amount",
      label: "공시지가(원/m²)",
      numeric: true,
    },
  ];

  const bodyRows = landTaxAssessedDataSets.map(d => {
    return {
      id: String(d.tax_year),
      date: d.tax_year,
      amount: d.tax_price,
    };
  });

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "1.2rem", fontWeight: 700 }}
          >
            개별공시지가
            <span className="text-sm text-gray-500">(㎡)</span>
            <MuiTooltip
              title={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="caption">
                    개별토지의 단위면적당 가격(원/m²)을 나타냅니다
                  </Typography>
                </Box>
              }
            >
              <IconButton
                sx={{
                  padding: "0 0 0 4px",
                }}
              >
                <HelpIcon fontSize="medium" color="primary" />
              </IconButton>
            </MuiTooltip>
          </Typography>
          <Box sx={{ position: "relative" }}>
            {landTaxAssessedDataSets.length === 0 && (
              <Box
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  opacity: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                }}
              >
                <Typography variant="subtitle2">
                  등록된 데이터가 없습니다.
                </Typography>
              </Box>
            )}
            <LineBarChart datasets={chartDatasets} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: "relative",
              minHeight: 380,
              height: 380,
            }}
          >
            {landTaxAssessedDataSets.length === 0 && (
              <Box
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  opacity: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                }}
              >
                <Typography variant="subtitle2">데이터 결과 없음</Typography>
              </Box>
            )}
            <TableBlock
              headCells={headCells}
              bodyRows={bodyRows}
              initialOrderBy="date"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
