import { USER_TYPE } from "./enums";

export const getUserType = (userType: USER_TYPE) => {
  switch (userType) {
    case USER_TYPE.NORMAL:
      return "Member";
    case USER_TYPE.ADMIN:
      return "Admin";
    default:
      return "Member";
  }
};
