import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";

import {
  IBuildingBlock,
  IBuildingBlockUnit,
  IBuildingBlockUnitSearch,
} from "../../graphql/types";

import { BuildingBlockUnit } from "../types";

interface UnitSearchBoxProps {
  searchBuildingBlockUnits: LazyQueryExecFunction<
    IBuildingBlockUnitSearch,
    OperationVariables
  >;
  loadingBuildingBlockUnits: boolean;
  buildingBlockUnits: BuildingBlockUnit[];
  selectedBuildingBlock: IBuildingBlock;
  selectedBuildingBlockUnit: IBuildingBlockUnit;
  disabled: boolean;
}

const UnitSearchBox: React.FC<UnitSearchBoxProps> = ({
  searchBuildingBlockUnits,
  loadingBuildingBlockUnits,
  buildingBlockUnits,
  selectedBuildingBlock,
  selectedBuildingBlockUnit,
  disabled,
}) => {
  const theme = useTheme();

  const handleBlockUnitChange = (e: SelectChangeEvent<string>) => {
    searchBuildingBlockUnits({
      variables: {
        unit_id: e.target.value,
        building_id: selectedBuildingBlock.building_id,
      },
    });
  };

  return (
    <Box sx={{ position: "relative" }} width={1}>
      {loadingBuildingBlockUnits ? (
        <LinearProgress variant="indeterminate" />
      ) : (
        <Box sx={{ height: "4px" }} />
      )}
      <FormControl
        fullWidth
        disabled={disabled}
        sx={{
          backgroundColor: disabled ? theme.palette.grey[200] : "initial",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {!selectedBuildingBlockUnit && (
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.text.secondary }}
          >
            <InputLabel shrink={false}>
              <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.text.secondary }}
              >
                호수 (Unit)
              </Typography>
            </InputLabel>
          </Typography>
        )}
        <Select
          onChange={handleBlockUnitChange}
          value={selectedBuildingBlockUnit?.unit?._id ?? ""}
          disabled={disabled}
          MenuProps={{
            PaperProps: { sx: { maxHeight: 250 } },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          sx={{
            "& .MuiSelect-multiple": {
              py: theme.spacing(1),
            },
            "&.MuiInputBase-root": {
              borderRadius: 0,
            },
            ".MuiOutlinedInput-root:hover": {
              fieldset: {
                borderColor: theme.palette.grey[200],
              },
            },
            fieldset: {
              "&.MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.grey[200]} !important`,
              },
            },
          }}
        >
          {buildingBlockUnits
            .sort((a, b) => parseInt(a.name) - parseInt(b.name))
            .map(buildingBlockUnit => {
              return (
                <MenuItem
                  key={buildingBlockUnit._id}
                  value={buildingBlockUnit._id}
                >
                  <Typography variant="subtitle2">
                    {buildingBlockUnit.name}
                  </Typography>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default UnitSearchBox;
