import React, { Suspense } from "react";
import { Box, Container, Grid, useTheme } from "@mui/material";

import {
  BUILDING_SEARCH_KEY,
  IBuilding,
  IBuildingBlock,
  IBuildingBlockUnit,
  UNIT_SEARCH_KEY,
} from "../../../graphql/types";
import { NearbyPlaces } from "../UnitDetailsPage";
import LandTaxAssessedPrices from "../../../modules/LandTaxAssessedPrices";

const ProjectSummary = React.lazy(
  () => import("../../../modules/ProjectSummary")
);
const BuildingInformation = React.lazy(
  () => import("../../../modules/BuildingInformation")
);
const LandInformation = React.lazy(
  () => import("../../../modules/LandInformation")
);
const MarketPriceEstimation = React.lazy(
  () => import("../../../modules/MarketPriceEstimation")
);
const MarketEnvironment = React.lazy(
  () => import("../../../modules/MarketEnvironment")
);
const SimilarityComparison = React.lazy(
  () => import("../../../modules/SimilarityComparison")
);
const WeFundingAnalysis = React.lazy(
  () => import("../../../modules/WeFundingAnalysis")
);
const GraphForecast = React.lazy(
  () => import("../../../modules/GraphForecast")
);
const FloorTrends = React.lazy(() => import("../../../modules/FloorTrends"));
const Transaction = React.lazy(() => import("../../../modules/Transaction"));
const Auctions = React.lazy(() => import("../../../modules/Auctions"));
const TaxAssessedPrices = React.lazy(
  () => import("../../../modules/TaxAssessedPrices")
);

interface UnitDetailsResultsProps {
  selectedBuilding: IBuilding;
  selectedBuildingBlock: IBuildingBlock;
  selectedBuildingBlockUnit: IBuildingBlockUnit;
  nearbyPlaces: NearbyPlaces;
}

const UnitDetailsResults: React.FC<UnitDetailsResultsProps> = ({
  selectedBuilding,
  selectedBuildingBlock,
  selectedBuildingBlockUnit,
  nearbyPlaces,
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          content: '""',
          zIndex: -2,
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: `linear-gradient(to top, ${theme.palette.common.white} 70%, ${theme.palette.grey[200]} 95%, ${theme.palette.common.black} 100% )`,
          transform: `translate3d(0px, 20px, 0) scale(0.98)`,
          filter: `blur(20px)`,
          opacity: 0.7,
          transition: `opacity 0.3s`,
        }}
      />
      <Box sx={{ backgroundColor: "white", p: 3 }}>
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Box width={1}>
            <Box display="flex">
              <img
                src="/img/logo-only.png"
                alt="logo"
                style={{ width: 40, height: 35, marginRight: 10 }}
              />
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                가치 보고서
              </h2>
            </Box>
            <Grid container sx={{ mt: 4 }}>
              <Suspense fallback={<div>프로젝트개요 로딩중...</div>}>
                <ProjectSummary
                  selectedBuilding={selectedBuilding}
                  selectedBuildingBlockUnit={selectedBuildingBlockUnit}
                />
              </Suspense>
            </Grid>
            <Grid container sx={{ mt: 4 }}>
              <Suspense fallback={<div>건물정보 로딩중...</div>}>
                <BuildingInformation
                  selectedBuildingBlock={selectedBuildingBlock}
                />
              </Suspense>
            </Grid>
            <Grid container sx={{ mt: 4 }}>
              <LandInformation
                selectedBuildingBlockUnit={selectedBuildingBlockUnit}
              />
            </Grid>
            <Container maxWidth="lg" disableGutters sx={{ mt: 4 }}>
              <Suspense fallback={<div>개별공시지가 로딩중...</div>}>
                {selectedBuildingBlockUnit ? (
                  <LandTaxAssessedPrices
                    selectedBuildingBlockUnit={selectedBuildingBlockUnit}
                  />
                ) : null}
              </Suspense>
            </Container>
            <Grid container sx={{ mt: 4 }}>
              <Suspense fallback={<div>추정시세 로딩중...</div>}>
                <MarketPriceEstimation
                  selectedBuildingBlockUnit={selectedBuildingBlockUnit}
                />
              </Suspense>
            </Grid>
          </Box>
        </Container>

        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Suspense fallback={<div>시장환경 로딩중...</div>}>
            <MarketEnvironment nearbyPlaces={nearbyPlaces} />
          </Suspense>
        </Container>

        {process.env.REACT_APP_WEFUNDING_PROTOTYPE_ENABLED === "true" && (
          <Container maxWidth="lg" disableGutters sx={{ mt: 4 }}>
            <Suspense fallback={<div>WeFunding Demo 로딩중...</div>}>
              <WeFundingAnalysis
                selectedBuildingBlockUnit={selectedBuildingBlockUnit}
              />
            </Suspense>
          </Container>
        )}

        <Container maxWidth="lg" disableGutters sx={{ mt: 4 }}>
          <Suspense fallback={<div>1년 추정시세 로딩중...</div>}>
            <Box width={1}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <GraphForecast
                    selectedBuildingBlockUnit={selectedBuildingBlockUnit}
                  />
                </Grid>
              </Grid>
            </Box>
          </Suspense>
        </Container>

        <Container maxWidth="lg" disableGutters sx={{ mt: 4 }}>
          <Suspense fallback={<div>공동주택공시가격 로딩중...</div>}>
            {selectedBuilding && selectedBuildingBlockUnit ? (
              <TaxAssessedPrices
                selectedBuildingBlockUnit={selectedBuildingBlockUnit}
              />
            ) : null}
          </Suspense>
        </Container>

        <Container maxWidth="lg" disableGutters sx={{ mt: 4 }}>
          <Suspense fallback={<div>거래내역 로딩중...</div>}>
            {selectedBuilding && selectedBuildingBlockUnit ? (
              <Transaction
                selectedBuilding={selectedBuilding}
                selectedBuildingBlock={selectedBuildingBlock}
                selectedBuildingBlockUnit={selectedBuildingBlockUnit}
              />
            ) : null}
          </Suspense>
        </Container>

        <Container maxWidth="lg" disableGutters sx={{ mt: 4 }}>
          <Suspense fallback={<div>건물 층 분석 로딩중...</div>}>
            <FloorTrends />
          </Suspense>
        </Container>

        <Container maxWidth="lg" disableGutters sx={{ mt: 4 }}>
          <Suspense fallback={<div>경매내역 로딩중...</div>}>
            {selectedBuilding && selectedBuildingBlockUnit ? (
              <Auctions selectedBuildingBlockUnit={selectedBuildingBlockUnit} />
            ) : null}
          </Suspense>
        </Container>

        <Container maxWidth="lg" disableGutters sx={{ mt: 4 }}>
          <Suspense fallback={<div>로딩중...</div>}>
            {selectedBuildingBlock && selectedBuildingBlockUnit ? (
              <SimilarityComparison
                block={selectedBuildingBlock}
                unit={selectedBuildingBlockUnit[UNIT_SEARCH_KEY]}
                building={selectedBuildingBlockUnit[BUILDING_SEARCH_KEY]}
                constructionYear={
                  selectedBuildingBlockUnit?.building?.construction_year
                }
              />
            ) : null}
          </Suspense>
        </Container>
      </Box>
    </>
  );
};

export default UnitDetailsResults;
