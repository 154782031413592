import { useMemo } from "react";
import debounce from "lodash.debounce";

import {
  Autocomplete,
  Box,
  CircularProgress,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";

import {
  IBuilding,
  IBuildingSearch,
  SEARCH_TEXT_ADDRESS_KEY,
} from "../../graphql/types";

interface AddressSearchBoxProps {
  searchBuildings: LazyQueryExecFunction<IBuildingSearch, OperationVariables>;
  loadingBuildings: boolean;
  buildings: IBuildingSearch[typeof SEARCH_TEXT_ADDRESS_KEY];
  setSelectedBuilding: Function;
  resetSelection: () => void;
  selectedBuilding: IBuilding;
}

const BuildingSearchBox: React.FC<AddressSearchBoxProps> = ({
  setSelectedBuilding,
  resetSelection,
  searchBuildings,
  buildings,
  loadingBuildings,
}) => {
  const theme = useTheme();

  const handleInputChange = (_, newInputValue) => {
    searchBuildings({ variables: { input: `${newInputValue}` } });
  };

  const debouncedHandleInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box sx={{ position: "relative" }} width={1}>
      {loadingBuildings ? (
        <LinearProgress variant="indeterminate" />
      ) : (
        <Box sx={{ height: "4px" }} />
      )}
      <Autocomplete
        sx={{
          minWidth: 600,
          width: "100%",
          fieldset: {
            "&.MuiOutlinedInput-notchedOutline": {
              borderColor: `${theme.palette.grey[200]} !important`,
              borderRadius: 0,
            },
          },
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setSelectedBuilding(newValue);
          } else {
            resetSelection();
          }
        }}
        onInputChange={debouncedHandleInputChange}
        noOptionsText={
          <Box sx={{ position: "relative" }}>
            {loadingBuildings && (
              <Box
                sx={{
                  alignItems: "center",
                  backgroundColor: theme.palette.grey[200],
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  opacity: 0.8,
                  zIndex: 10,
                }}
              >
                <CircularProgress variant="indeterminate" size={30} />
                <Typography variant="subtitle2" mt={1.5}>
                  로딩중...
                </Typography>
              </Box>
            )}
            <div className="flex items-center justify-center p-3 text-center">
              보유데이터 중 일치하는 주소가 없습니다.
              <br />
              다시 검색해 주세요.
            </div>
            <hr />
            <div className="px-1 py-6 text-sm">
              <p>
                · 검색한 주소의 건축물대장 상 용도가 공동주택인지 확인해 주세요.
              </p>
              <p>
                · 신축건물의 경우, 데이터 수집 및 정제까지 일정 시간이
                소요됩니다.
              </p>
              <p>
                · 자이랜드는 도로명주소 안내시스템 (https://www.juso.go.kr)의
                주소정보를 기반으로 하고 있습니다.
              </p>
            </div>
          </Box>
        }
        options={buildings}
        getOptionLabel={option => (option as IBuilding).address_dong}
        filterOptions={option => option}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        style={{ width: "100%" }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              alignItems: "flex-start",
              justifyContent: "flex-start !important",
              borderBottom: `1px solid rgba(0,0,0,5%)`,
              display: "flex",
              verticalAlign: "top",
            }}
            {...props}
          >
            <RoomIcon />
            <Box
              sx={{
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start !important",
                textAlign: "left",
                marginLeft: 2,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {option.address_dong}
              </Typography>
              <Typography variant="subtitle2">
                ({option.address_road})
              </Typography>
              <Typography variant="subtitle2">
                {option.building_name}
              </Typography>
            </Box>
          </Box>
        )}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: false,
            }}
            placeholder="지번 주소 또는 도로명 주소를 검색하세요"
            variant="outlined"
          />
        )}
      />
    </Box>
  );
};

export default BuildingSearchBox;
