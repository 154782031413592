import React, { useState } from "react";
import { Box, Container, ToggleButton, useTheme } from "@mui/material";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

import {
  useAddressSearch,
  WrappedProps,
} from "../../components/AddressSearch/context";
import { Places } from "../../components/CategorySearch/types";
import AddressSearch from "../../components/AddressSearch";

import UnitDetailsResults from "./UnitDetailsResults";
import UnitDetailsMap from "./UnitDetailsMap";
import { KAKAO_MAP_TYPE } from "../../components/KakaoMap/types";
import KakaoRoadview from "../../components/KakaoRoadview.tsx/KakaoRoadview";
export interface NearbyPlaces {
  [key: string]: Places[];
}

const MIN_SCROLL_THRESHOLD = 50;

const UnitDetails = () => {
  const theme = useTheme();
  const {
    selectedBuilding,
    selectedBuildingBlock,
    selectedBuildingBlockUnit,
    loadingBuildingBlocks,
  }: WrappedProps = useAddressSearch();

  const [nearbyPlaces, setNearbyPlaces] = useState<{
    [key: string]: Places[];
  }>({});
  const [isScrollBelowThreshold, setIsScrollBelowThreshold] = useState(false);
  const [mapType, setMapType] = useState<KAKAO_MAP_TYPE>("ROADMAP");

  const isAllSelected =
    selectedBuilding && selectedBuildingBlock && selectedBuildingBlockUnit;

  const [lng, lat] = selectedBuildingBlock?.location?.coordinates ?? [
    127.0277353, 37.4987398,
  ];

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setIsScrollBelowThreshold(e.currentTarget.scrollTop > MIN_SCROLL_THRESHOLD);
  };

  const toggleMapType = () => {
    const newMapType = mapType === "ROADMAP" ? "USE_DISTRICT" : "ROADMAP";
    setMapType(newMapType);
  };

  return (
    <Container
      onScroll={onScroll}
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      disableGutters
    >
      <Container
        maxWidth="lg"
        sx={{
          position: "absolute",
          marginTop: isScrollBelowThreshold ? 0 : 2,
          left: 0,
          right: 0,
          zIndex: 10,
          transition: theme.transitions.create("all", {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
          }),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: theme.palette.common.white,
              boxShadow: theme.shadows[10],
              display: "flex",
              justifyContent: "center",
            }}
            height={1}
            width={1}
          >
            <AddressSearch isCompactMode={isScrollBelowThreshold} />
          </Box>
          <ToggleButton
            value="alignment"
            selected={mapType === "USE_DISTRICT"}
            onChange={toggleMapType}
            sx={{
              alignSelf: "flex-end",
              backgroundColor: theme.palette.common.white,
              boxShadow: theme.shadows[10],
              marginTop: "8px",
              display: "flex",
              gap: "4px",
              color: "#000000",

              "&.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
              },

              "&.Mui-selected:hover": {
                backgroundColor: theme.palette.primary.main,
              },

              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <SpaceDashboardIcon />
            지적편집도
          </ToggleButton>
        </Box>
      </Container>

      <Container
        maxWidth={false}
        disableGutters
        sx={{ pointerEvents: "none", position: "relative", zIndex: 0 }}
      >
        <UnitDetailsMap
          loadingBuildingBlocks={loadingBuildingBlocks}
          lat={lat}
          lng={lng}
          setNearbyPlaces={setNearbyPlaces}
          mapType={mapType}
        />
      </Container>

      {isAllSelected ? (
        <Container
          maxWidth="lg"
          sx={{
            flex: 1,
            mt: 10,
            marginTop: `-200px`,
            py: `30px`,
            position: "relative",
            transition: theme.transitions.create("all", {
              delay: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeInOut,
            }),
            zIndex: 0,
          }}
        >
          <UnitDetailsResults
            selectedBuilding={selectedBuilding}
            selectedBuildingBlock={selectedBuildingBlock}
            selectedBuildingBlockUnit={selectedBuildingBlockUnit}
            nearbyPlaces={nearbyPlaces}
          />
          <Box
            sx={{
              backgroundColor: "#ffffff",
              boxShadow:
                " rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
              borderRadius: "8px",
              position: "absolute",
              top: "-100px",
              right: "48px",
              width: "300px",
              height: "200px",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <KakaoRoadview
              position={{
                lat: selectedBuildingBlock.location.coordinates[1],
                lng: selectedBuildingBlock.location.coordinates[0],
              }}
              label={selectedBuilding.building_name || ""}
            />
          </Box>
        </Container>
      ) : null}
    </Container>
  );
};

export default UnitDetails;
