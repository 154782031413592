import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

import KakaoMap from "../../../components/KakaoMap/KakaoMap";

import { NearbyPlaces } from "../UnitDetailsPage";
import { KAKAO_MAP_TYPE } from "../../../components/KakaoMap/types";

interface Props {
  loadingBuildingBlocks: boolean;
  lat: number;
  lng: number;
  setNearbyPlaces: React.Dispatch<React.SetStateAction<NearbyPlaces>>;
  mapType?: KAKAO_MAP_TYPE;
}

export default function UnitDetailsMap({
  loadingBuildingBlocks,
  lat,
  lng,
  setNearbyPlaces,
  mapType = "ROADMAP",
}: Props) {
  const theme = useTheme();

  return (
    <>
      {loadingBuildingBlocks && (
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: theme.palette.grey[300],
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            opacity: 0.7,
            position: "absolute",
            zIndex: 9,
          }}
        >
          <CircularProgress size={30} />
          <Typography variant="body1" mt={0.5}>
            로딩중
          </Typography>
        </Box>
      )}
      <KakaoMap
        lat={lat}
        lng={lng}
        setNearbyPlaces={setNearbyPlaces}
        type={mapType}
      />
    </>
  );
}
