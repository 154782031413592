import React from "react";
import { Map, MapMarker, MapTypeId } from "react-kakao-maps-sdk";

import xailandLogoMarker from "../../assets/image/logo/logo-marker-removebg.png";

import CategoryMarkers from "../CategorySearch/CategoryMarkers";
import { Places } from "../CategorySearch/types";
import { KAKAO_MAP_TYPE_ID } from "./constants";
import { KAKAO_MAP_TYPE } from "./types";

interface Props {
  lat: number;
  lng: number;
  setNearbyPlaces: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Places[];
    }>
  >;
  type?: KAKAO_MAP_TYPE;
}

export default function KakaoMap({
  lat,
  lng,
  setNearbyPlaces,
  type = "ROADMAP",
}: Props) {
  const position = { lat, lng };
  return (
    <Map // 지도를 표시할 Container
      center={position}
      style={{
        width: "100%",
        height: "calc(100vh - 95px)",
      }}
      level={4} // 지도의 확대 레벨
    >
      <MapTypeId type={KAKAO_MAP_TYPE_ID[type]} />
      <MapMarker
        position={position}
        image={{
          src: xailandLogoMarker, // 마커이미지의 주소입니다
          size: {
            width: 64,
            height: 69,
          }, // 마커이미지의 크기입니다
        }}
      />

      <CategoryMarkers lat={lat} lng={lng} setNearbyPlaces={setNearbyPlaces} />
    </Map>
  );
}
