import { Box } from "@mui/material";
import { Roadview, RoadviewMarker } from "react-kakao-maps-sdk";

interface Props {
  position: {
    lat: number;
    lng: number;
  };
  label: string;
}

export default function KakaoRoadview({ position, label }: Props) {
  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Roadview
        position={{ ...position, radius: 50 }}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <RoadviewMarker
          position={position}
          onCreate={marker => {
            const roadview = marker.getMap() as kakao.maps.Roadview;

            // viewpoint(화면좌표)값을 추출할 수 있는 projection 객체를 가져옵니다.
            const projection = roadview.getProjection();

            // 마커의 position과 altitude값을 통해 viewpoint값(화면좌표)를 추출합니다.
            const viewpoint = projection.viewpointFromCoords(
              marker.getPosition(),
              marker.getAltitude()
            );

            viewpoint.zoom = -3;

            // 로드뷰에 뷰포인트를 설정합니다.
            roadview.setViewpoint(viewpoint);
            roadview.relayout();
          }}
        >
          {label && <div>{label}</div>}
        </RoadviewMarker>
      </Roadview>
    </Box>
  );
}
