import { Box, Container, Typography } from "@mui/material";

interface Props {
  title: string;
  icon?: React.ReactNode;
  description?: string;
}

export default function CardBox({ title, icon, description }: Props) {
  return (
    <Container
      sx={{
        bgcolor: "#E6F5FF",
        width: "100%",
        borderRadius: 2,
        padding: 6,
        display: "flex",
        flexDirection: "column",
        gap: 6,
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid transparent",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",

        "&:hover": {
          border: "2px solid #252B48",
        },
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            color: "#252B48",
          }}
        >
          {title}
        </Typography>
      </Box>
      {icon && (
        <Box
          sx={{
            borderRadius: "50%",
            bgcolor: "#FFFFFF",
            padding: 2,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
          }}
        >
          {icon}
        </Box>
      )}
      {description && (
        <Box
          textAlign="center"
          sx={{
            wordBreak: "keep-all",
          }}
        >
          {description}
        </Box>
      )}
    </Container>
  );
}
