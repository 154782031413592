export const SEARCH_TEXT_ADDRESS_KEY = "SearchTextAddress";
export const BLOCK_SEARCH_KEY = "block";
export const UNIT_SEARCH_KEY = "unit";
export const BUILDING_SEARCH_KEY = "building";
export const TRANSACTION_SEARCH_KEY = "SearchMapTransactions";
export const UNITS_BY_BLOCK_KEY = "units";

export type IBuilding = {
  _id: string;
  address_dong: string;
  address_road: string;
  building_name: string;
  block_count: number;
  block_ids: string[];
  block_names: string[];
  score: string;
  construction_year?: number;
  above_floor_number?: number;
  land_area?: number;
  land_use_name?: string;
  use_area_name?: string;
};

export type IBuildingBlock = {
  _id: string;
  type: number[];
  building_id: string;
  block_name: string;
  unit_count: string;
  unit_ids: string[];
  unit_names: string[];
  location: {
    coordinates: [number, number];
  };
  title: {
    archArea: number;
    construction_day: number;
    construction_month: number;
    construction_year: number;
    dongNm: string;
    emgenUseElvtCnt: number;
    etcPurps: string;
    /**
     * 지상 층 수
     */
    grndFlrCnt: number;
    /**
     * 지하 층 수
     */
    ugrndFlrCnt: number;
    /**
     * 세대 수
     */
    hhldCnt: number;
    hoCnt: number;
    rideUseElvtCnt: number;
    totalElvtCnt: number;
    useAprDay: string;
  }[];
};

interface TaxInfo {
  tax_year: number;
  tax_price: number;
}

export type IBuildingBlockUnit = {
  unit: {
    _id: string;
    block_id: string;
    block_name: string;
    building_id: string;
    floor: number;
    public_area?: number;
    floor_number_name: string;
    private_area: number;

    /**
     * description
     * 1: apartment
     * 2: villa
     * 3: officetel
     * 4: individual house
     */
    type: number;
    unit_name: string;
    prediction: {
      amount: string;
      growth_1: number;
      lower_limit: number;
      tax_price?: number;
      upper_limit: number;
      date?: string;
    };
    location: {
      coordinates: [number, number];
    };
    tax_info: TaxInfo[];
  };
  building: {
    __typename: string;
    _id: string;
    above_floor_number: string;
    address_dong: string;
    address_road: string;
    block_count: string;
    building_name: string;
    bun: string;
    construction_year: number;
    /**
     * TODO: delete when server gives construction_month
     * queyr: UNIT_SEARCH_TEXT -> building -> construction_month
     */
    construction_month: number;
    dong_code: string;
    dong_name: string;
    elevator_count: string;
    gu_code: string;
    gu_name: string;
    ji: string;
    land_area: string;
    land_height: string;
    land_purpose: string;
    land_shape: string;
    land_sqm: string;
    land_updated: string;
    land_use_name: string;
    location: {
      coordinates: [number, number];
    };
    new_bun: string;
    new_ji: string;
    parking_count: number;
    road_face: string;
    si_code: string;
    si_name: string;
    structure_code?: string;
    structure_name?: string;
    type: number[];
    unit_count: number;
    use_area_name: string;
    land_tax_info: TaxInfo[];
  };
};

export type ITransaction = {
  type: string;
  building_id: string;
  floor: number;
  address_dong: string;
  private_area: number;
  location: {
    type: string;
    coordinates: [number, number];
  };
  amount: string;
  date: string;
  distance: number;
};

export type IUnitsByBlock = {
  _id: string;
  block_id: string;
  floor: number;
  private_area: number;
  type: string;
  unit_name: string;
  prediction: {
    amount: string;
    tax_price: number;
  };
};

export type IBuildingSearch = {
  [SEARCH_TEXT_ADDRESS_KEY]: IBuilding[];
};

export type IBuildingBlockSearch = {
  [BLOCK_SEARCH_KEY]: IBuildingBlock;
};

export type IBuildingBlockUnitSearch = IBuildingBlockUnit;

export type ITransactionsSearch = {
  [TRANSACTION_SEARCH_KEY]: ITransaction[];
};

export type IUnitsByBlockSearch = {
  [UNITS_BY_BLOCK_KEY]: IUnitsByBlock[];
};
