import gql from "graphql-tag";

export const BUILDING_SEARCH_TEXT = gql`
  query SearchTextAddress($input: String) {
    SearchTextAddress(input: $input) {
      _id
      address_dong
      address_road
      building_name
      block_count
      block_ids
      block_names
      score
    }
  }
`;

export const BLOCK_SEARCH_TEXT = gql`
  query Block($block_id: String) {
    block(query: { _id: $block_id }) {
      _id
      type
      building_id
      block_name
      unit_count
      unit_ids
      unit_names
      location {
        coordinates
      }
      title {
        archArea
        construction_day
        construction_month
        construction_year
        dongNm
        emgenUseElvtCnt
        etcPurps
        grndFlrCnt
        hhldCnt
        hoCnt
        rideUseElvtCnt
        totalElvtCnt
        ugrndFlrCnt
        useAprDay
      }
    }
  }
`;

export const UNIT_SEARCH_TEXT = gql`
  query UnitAndBuilding($unit_id: String, $building_id: String) {
    unit(query: { _id: $unit_id }) {
      _id
      block_id
      block_name
      building_id
      floor
      public_area
      floor_number_name
      private_area
      type
      unit_name
      prediction {
        amount
        growth_1
        lower_limit
        tax_price
        upper_limit
        date
      }
      location {
        coordinates
      }
      tax_info {
        tax_year
        tax_price
      }
    }

    building(query: { _id: $building_id }) {
      _id
      address_dong
      address_road
      block_count
      building_name
      bun
      construction_year
      construction_month
      dong_code
      dong_name
      gu_code
      gu_name
      ji
      elevator_count
      parking_count
      land_area
      land_height
      land_purpose
      land_shape
      land_sqm
      unit_count
      above_floor_number
      parking_count
      land_updated
      land_use_name
      new_bun
      new_ji
      road_face
      si_code
      si_name
      structure_code
      structure_name
      use_area_name
      location {
        coordinates
      }
      land_tax_info {
        tax_year
        tax_price
      }
    }
  }
`;

export const UNITS_BY_BLOCK = gql`
  query UnitsByBlock($block_id: String) {
    units(query: { block_id: $block_id }) {
      _id
      block_id
      floor
      private_area
      type
      unit_name
      prediction {
        amount
        tax_price
      }
    }
  }
`;

export const BLOCKS = gql`
  query Blocks {
    blocks {
      _id
      block_name
      building_id
      type
      location {
        type
        coordinates
      }
      unit_count
      unit_ids
    }
  }
`;

export const UNIT = gql`
  query unit($unit_id: String) {
    unit(query: { _id: $unit_id }) {
      _id
      unit_name
      type
      block_id
      building_id
      floor
      floor_number_name
      private_area
      public_area
    }
  }
`;

export const USER = gql`
  query user($id: String) {
    user(query: { _id: $id }) {
      _id
      name
      roles
    }
  }
`;

export const BUILDING_TRANSACTION = gql`
  query transactions($building_id: String) {
    transactions(query: { building_id: $building_id }) {
      _id
      building_id
      date
      floor
      address_dong
      amount
      private_area
      cancellation_date
    }
  }
`;

export const UNIT_TRANSACTION = gql`
  query transactions($unit_id: String) {
    transactions(query: { unit_id: $unit_id }) {
      date
      block_id
      _id
      unit_id
      amount
    }
  }
`;

export const PREDICTIONS = gql`
  query predictions($unit_id: String) {
    predictions(query: { unit_id: $unit_id }) {
      date
      _id
      unit_id
      amount
      block_id
      growth_1
    }
  }
`;

export const PREDICTIONS_CUSTOM = gql`
  query predictions($unit_ids: [String]) {
    predictions(query: { unit_id_in: $unit_ids }) {
      date
      _id
      unit_id
      amount
      block_id
      growth_1
    }
  }
`;

export const UNIT_CUSTOM = gql`
  query units($unit_ids: [String]) {
    units(query: { _id_in: $unit_ids }) {
      _id
      unit_name
      type
      block_id
      floor
      private_area
    }
  }
`;

export const PREDICTIONS2 = gql`
  query predictions($block_id: String) {
    predictions(query: { block_id: $block_id }) {
      date
      _id
      unit_id
      amount
      block_id
      growth_1
    }
  }
`;

export const UNIT_CUSTOM_TRANSACTION = gql`
  query UnitCustomTransaction($input: String) {
    UnitCustomTransaction(input: $input) {
      _id
      date
      amount
      block {
        _id
        block_name
        location {
          type
          coordinates
        }
        unit_count
      }
      unit {
        _id
        unit_name
        floor
        private_area
      }
    }
  }
`;

export const TRANSACTION_CUSTOM = gql`
  query Transactions($unit_ids: [String]) {
    transactions(query: { unit_id_in: $unit_ids }) {
      _id
      date
      unit_id
      amount
    }
  }
`;

export const SEARCH_MAP_UNITS = gql`
  query SearchMapUnits(
    $lng: Float
    $lat: Float
    $unit_type: Int
    $radius: Float
    $area_min: Float
    $area_max: Float
    $floor_min: Int
    $floor_max: Int
  ) {
    SearchMapUnits(
      input: {
        lng: $lng
        lat: $lat
        unit_type: $unit_type
        radius: $radius
        area_min: $area_min
        area_max: $area_max
        floor_min: $floor_min
        floor_max: $floor_max
      }
    ) {
      _id
      type
      building_id
      private_area
      location {
        coordinates
      }
      distance
    }
  }
`;

export const SEARCH_MAP_TRANSACTIONS = gql`
  query SearchMapTransactions(
    $lng: Float
    $lat: Float
    $radius: Float
    $area_min: Float
    $area_max: Float
    $unit_type: Int
    $construction_year_min: Int
    $construction_year_max: Int
  ) {
    SearchMapTransactions(
      input: {
        lng: $lng
        lat: $lat
        radius: $radius
        area_min: $area_min
        area_max: $area_max
        unit_type: $unit_type
        construction_year_min: $construction_year_min
        construction_year_max: $construction_year_max
      }
    ) {
      type
      construction_year
      construction_month
      building_id
      floor
      address_dong
      private_area
      location {
        type
        coordinates
      }
      use_area_name
      building_name
      amount
      date
      distance
    }
  }
`;

export const SEARCH_MAP_RENTALS = gql`
  query SearchMapRentals(
    $lng: Float
    $lat: Float
    $radius: Float
    $area_min: Float
    $area_max: Float
    $unit_type: Int
    $construction_year_min: Int
    $construction_year_max: Int
    $jeonse: Boolean
  ) {
    SearchMapRentals(
      input: {
        lng: $lng
        lat: $lat
        radius: $radius
        area_min: $area_min
        area_max: $area_max
        unit_type: $unit_type
        construction_year_min: $construction_year_min
        construction_year_max: $construction_year_max
        jeonse: $jeonse
      }
    ) {
      construction_year
      construction_month
      address_dong
      deposit_amount
      monthly_rental_price
      jeonse_converted_amount
      building_id
      date
      floor
      private_area
      type
      location {
        type
        coordinates
      }
      distance
      building_name
      use_area_name
    }
  }
`;

export const SEARCH_MAP_BLOCK = gql`
  query SearchMapBlock($lng: String, $lat: String, $level: String) {
    SearchMapBlock(input: { lng: $lng, lat: $lat, level: $level }) {
      _id
      block_name
      building_id
      type
      location {
        type
        coordinates
      }
      unit_count
      unit_ids
    }
  }
`;

export const MATCH_LOOK_UP_GEO_NEAR = gql`
  query MatchLookupGeoNear($lng: String, $lat: String, $level: String) {
    MatchLookupGeoNear(input: { lng: $lng, lat: $lat, level: $level }) {
      _id
      block_name
      location {
        coordinates
      }
      unit_count
      unit_ids
      block_prediction {
        _id
        amount
        date
        unit_id
        growth_1
      }
    }
  }
`;

export const UNIT_TRANSACTION_CUSTOM = gql`
  query UnitTransactionCustom($unit_ids: [String]) {
    UnitTransactionCustom(query: { _id_in: $unit_ids }) {
      _id
      unit_name
      type
      block_id
      building_id
      floor
      floor_number_name
      private_area
      public_area
      transaction_docs {
        _id
        date
        unit_id
        amount
      }
    }
  }
`;

export const RETRIEVE_AUCTION_DATA = gql`
  query RetrieveAuctionData($input: String) {
    RetrieveAuctionData(input: $input) {
      quarterly_aggregations {
        quarter_number
        quarter_start
        quarter_end
        number_of_auctions
        number_of_sales
        sale_to_appraised_ratio
      }
    }
  }
`;
