import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CardBox from "../../components/CardBox";

export default function SelectionPage() {
  return (
    <Container
      sx={{
        paddingTop: 10,
      }}
    >
      <Box paddingBottom={5}>
        <Typography
          align="center"
          variant="h4"
          color="#252B48"
          fontWeight={700}
        >
          원하는 자이랜드의 서비스를 선택하세요
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 6,
        }}
      >
        <Link to="/report">
          <Box width={350}>
            <CardBox
              title="Value Report"
              icon={
                <SummarizeIcon
                  fontSize="large"
                  sx={{
                    color: "#72BAFC",
                  }}
                />
              }
              description="정확한 AI 모델을 이용한 가치 평가 리포트를 만나보세요"
            />
          </Box>
        </Link>
        <Link to="/dashboard">
          <Box width={350}>
            <CardBox
              title="Dashboards"
              icon={
                <AssessmentIcon
                  fontSize="large"
                  sx={{
                    color: "#72BAFC",
                  }}
                />
              }
              description="다양한 부동산 종류별 대시보드를 확인하세요"
            />
          </Box>
        </Link>
      </Box>
    </Container>
  );
}
