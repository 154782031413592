import React, { useCallback, useEffect, useMemo } from "react";
import { useMap } from "react-kakao-maps-sdk";

import { CategoryCode } from "./enum";
import { Places } from "./types";

interface CategoryMarkersProps {
  lat: number;
  lng: number;
  setNearbyPlaces: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Places[];
    }>
  >;
}

const CategoryMarkers: React.FC<CategoryMarkersProps> = ({
  lat,
  lng,
  setNearbyPlaces,
}) => {
  const map = useMap();

  const handleCategorySearchResult = useCallback(
    (data, status, pagination, categoryItem) => {
      if (status === kakao.maps.services.Status.OK) {
        // 정상적으로 검색이 완료됐으면 지도에 마커를 표출합니다
        setNearbyPlaces(places => {
          return {
            ...places,
            [categoryItem]: data,
          };
        });
      } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
        // 검색결과가 없는경우 해야할 처리가 있다면 이곳에 작성해 주세요
        console.warn(`ZERO_RESULT: ${categoryItem}`);
      } else if (status === kakao.maps.services.Status.ERROR) {
        // 에러로 인해 검색결과가 나오지 않은 경우 해야할 처리가 있다면 이곳에 작성해 주세요
        console.error(`ERROR: ${categoryItem}`);
      }
    },
    [setNearbyPlaces]
  );

  const places = useMemo(() => new kakao.maps.services.Places(map), [map]);

  const searchPlaces = useCallback(() => {
    const categorySearches = Object.keys(CategoryCode).map(
      (categoryItem: CategoryCode) =>
        places.categorySearch(
          categoryItem,
          (data, status, pagination) =>
            handleCategorySearchResult(data, status, pagination, categoryItem),
          {
            location: new kakao.maps.LatLng(lat, lng),
          }
        )
    );

    Promise.all(categorySearches)
      .then(() => {
        console.info("DONE");
      })
      .catch(error => {
        console.error(error.message);
      });
  }, [lat, lng, places, handleCategorySearchResult]);

  useEffect(() => {
    if (lat && lng) searchPlaces();
  }, [searchPlaces, lat, lng]);

  return null;
};

export default CategoryMarkers;
