import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";

import { useRealmApp, RealmAppProvider } from "./RealmApp";
import RealmApolloProvider from "./graphql/RealmApolloProvider";
import Layout from "./components/Layout";
import LoginPage from "./pages/LoginPage";
import UnitDetailsPage from "./pages/UnitDetailsPage";
import { routes } from "./routes";
import SelectionPage from "./pages/SelectionPage";

// 코드 분할
const AdminPage = React.lazy(() => import("./admin/pages/AdminPage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
const DashboardPage = React.lazy(() => import("./pages/DashboardPage"));

const RequireLoggedInUser = ({ children }: { children: JSX.Element }) => {
  const app = useRealmApp();
  return app.currentUser ? children : <LoginPage />;
};

const theme = createTheme({
  palette: {
    primary: {
      main: `rgb(128, 217, 138)`,
      light: `rgba(128, 217, 138, 50%)`,
      dark: "#489651",
    },
    secondary: {
      main: "#252B48",
      light: "#3A3F5C",
      dark: "#1E2139",
    },
    info: {
      main: "#72BAFC",
      light: "#A3D2FC",
      dark: "#4A8CCF",
    },
  },
});

export default function App() {
  return (
    <RealmAppProvider appId={process.env.REACT_APP_APP_ID}>
      <RequireLoggedInUser>
        <RealmApolloProvider>
          <ThemeProvider theme={theme}>
            <Suspense fallback={null}>
              <BrowserRouter>
                <Routes>
                  <Route path={routes.root} element={<Layout />}>
                    <Route index element={<SelectionPage />} />
                    <Route path={routes.report} element={<UnitDetailsPage />} />
                    <Route path={routes.profile} element={<ProfilePage />} />
                    <Route
                      path={routes.dashborad}
                      element={<DashboardPage />}
                    />
                  </Route>
                  <Route path={routes.admin}>
                    <Route index element={<AdminPage />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </Suspense>
          </ThemeProvider>
        </RealmApolloProvider>
      </RequireLoggedInUser>
    </RealmAppProvider>
  );
}
