import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { visuallyHidden } from "@mui/utils";
import formatKoreanCurrency from "../../../utils/formatKoreanCurrency";

export type HeadCells = { id: string; label: string; numeric?: boolean }[];

interface Props {
  headCells: HeadCells;
  bodyRows: ({ id: string } & Record<string, any>)[];
  initialOrderBy?: string;
}

type ExtractIds<T extends { id: string }[]> = {
  [K in T[number]["id"]]: true;
};

type OrderBy = keyof ExtractIds<HeadCells>;

type Order = "asc" | "desc";
export default function TableBlock({
  bodyRows,
  headCells,
  initialOrderBy,
}: Props) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<OrderBy>(initialOrderBy || "");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const [tablePageIndex, setTablePageIndex] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else if (selectedIndex === 0) {
      newSelected = selected.slice(1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const headCellsWithOptions = headCells.map(data => {
    return {
      id: data.id,
      numeric: data.numeric ?? false,
      disablePadding: true,
      label: data.label,
    };
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setTablePageIndex(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePageIndex(0);
  };

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <Paper sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <TableContainer sx={{ flex: 1 }}>
          <Table aria-labelledby="tableTitle" size="medium" stickyHeader>
            <EnhancedTableHead
              headCells={headCellsWithOptions}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={2}
            />
            <TableBody>
              {stableSort(bodyRows, getComparator<any>(order, orderBy))
                .slice(
                  tablePageIndex * rowsPerPage,
                  tablePageIndex * rowsPerPage + rowsPerPage
                )
                .map(row => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(row.id)}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{
                        "& .MuiTableCell-root": {
                          py: 2,
                          px: 1,
                        },
                      }}
                    >
                      {Object.entries(row).map(([key, value]) => {
                        if (key === "id") return <></>;

                        return (
                          <TableCell key={key} align="right">
                            {key === "amount"
                              ? formatKoreanCurrency(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={bodyRows.length}
          onPageChange={handleChangePage}
          page={tablePageIndex}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

interface EnhancedTableHeadProps {
  headCells: {
    id: string;
    label: string;
    numeric: boolean;
    disablePadding: boolean;
  }[];
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  setOrderBy?: (orderBy: string) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const theme = useTheme();

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: theme.palette.action.hover,
              "&.MuiTableCell-root": {
                py: 1,
                px: 0.5,
              },
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              color={theme.palette.primary.main}
            >
              <div dangerouslySetInnerHTML={{ __html: headCell.label }} />
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getComparator<Key>(
  order: Order,
  orderBy: keyof Key
): (a: { [key in keyof Key]: any }, b: { [key in keyof Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
