export const AUTH_ERROR_CODES = {
  INVALID_USERNAME: "invalid username",
  INVALID_USERNAME_PASSWORD: "invalid username/password",
  INVALID_PASSWORD: "invalid password",
  UNAUTHORIZED: "401",
  NAME_IN_USE: "name already in use",
  CONFLICT: "409",
  PASSWORD_POLICY: "password must be between 6 and 128 characters",
  BAD_REQUEST: "400",
};

export const AUTH_MODE = {
  LOGIN: "login",
  REGISTER: "register",
};
