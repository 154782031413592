import { useState, useEffect, useContext, createContext } from "react";
import * as Realm from "realm-web";

const RealmAppContext = createContext(null);

export const useRealmApp = () => {
  const app = useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};

export const RealmAppProvider = ({
  appId,
  children,
}: {
  children: React.ReactNode;
  appId: string;
}) => {
  const [app, setApp] = useState(new Realm.App(appId));
  const [isLoadingApp, setIsLoadingApp] = useState(false);
  useEffect(() => {
    setApp(new Realm.App(appId));
  }, [appId]);

  const [currentUser, setCurrentUser] = useState(app.currentUser);
  async function logIn(credentials) {
    setIsLoadingApp(true);
    await app.logIn(credentials);
    setCurrentUser(app.currentUser);
    setIsLoadingApp(false);
  }
  async function logOut() {
    setIsLoadingApp(true);
    await app.currentUser?.logOut();
    setCurrentUser(app.currentUser);
    setIsLoadingApp(false);
  }

  const wrapped = { ...app, currentUser, isLoadingApp, logIn, logOut };

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};
