import { SetStateAction } from "react";
import {
  useLazyQuery,
  LazyQueryExecFunction,
  OperationVariables,
} from "@apollo/client";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  BUILDING_SEARCH_TEXT,
  BLOCK_SEARCH_TEXT,
  UNIT_SEARCH_TEXT,
} from "../../graphql/queries";
import {
  IBuilding,
  IBuildingBlock,
  IBuildingBlockUnit,
  IBuildingSearch,
  IBuildingBlockSearch,
  IBuildingBlockUnitSearch,
  SEARCH_TEXT_ADDRESS_KEY,
  BLOCK_SEARCH_KEY,
} from "../../graphql/types";
import { SIZE_UNIT } from "../../enum";
import { routes } from "../../routes";

import { BuildingBlock, BuildingBlockUnit } from "../types";

const AddressSearchContext = createContext(null);

export const useAddressSearch = () => {
  const addressSearch = useContext(AddressSearchContext);
  if (!addressSearch) {
    throw new Error(
      `You must call useAddressSearch inside of a <AddressSearchProvider />`
    );
  }
  return addressSearch;
};

interface AddressSearchProviderProps {
  children: React.ReactNode;
  resultUrl: string;
}

interface SearchPropertiesProps {
  unit: typeof SIZE_UNIT[keyof typeof SIZE_UNIT];
  min: number;
  max: number;
}

export interface WrappedProps {
  selectedBuilding: IBuilding;
  selectedBuildingBlock: IBuildingBlock;
  selectedBuildingBlockUnit: IBuildingBlockUnit;
  setSelectedBuilding: Dispatch<IBuilding>;
  setSelectedBuildingBlock: Dispatch<IBuildingBlock>;
  setSelectedBuildingBlockUnit: Dispatch<IBuildingBlockUnit>;
  mapZoomLevel: number;
  setMapZoomLevel: Dispatch<number>;
  searchProperties: SearchPropertiesProps;
  setSearchProperties: Dispatch<SearchPropertiesProps>;
  searchBuildings: LazyQueryExecFunction<IBuildingSearch, OperationVariables>;
  resultBuildings: IBuildingSearch;
  loadingBuildings: boolean;
  searchBuildingBlocks: LazyQueryExecFunction<
    IBuildingBlockSearch,
    OperationVariables
  >;
  resultBuildingBlocks: IBuildingBlockSearch;
  loadingBuildingBlocks: boolean;
  searchBuildingBlockUnits: LazyQueryExecFunction<
    IBuildingBlockUnitSearch,
    OperationVariables
  >;
  resultBuildingBlockUnits: IBuildingBlockUnitSearch;
  loadingBuildingBlockUnits: boolean;
  buildings: IBuildingSearch[typeof SEARCH_TEXT_ADDRESS_KEY];
  buildingBlocks: BuildingBlock[];
  setBuildingBlocks: Dispatch<SetStateAction<BuildingBlock[]>>;
  buildingBlockUnits: BuildingBlockUnit[];
  setBuildingBlockUnits: Dispatch<SetStateAction<BuildingBlockUnit[]>>;
}

export const AddressSearchProvider = ({
  children,
  resultUrl = routes.root,
}: AddressSearchProviderProps) => {
  // REACT CONTEXTS
  const [buildings, setBuildings] = useState<
    IBuildingSearch[typeof SEARCH_TEXT_ADDRESS_KEY]
  >([]);
  const [buildingBlocks, setBuildingBlocks] = useState<BuildingBlock[]>([]);
  const [buildingBlockUnits, setBuildingBlockUnits] = useState<
    BuildingBlockUnit[]
  >([]);

  // REACT QUERIES
  const [
    searchBuildings,
    { data: resultBuildings, loading: loadingBuildings },
  ] = useLazyQuery<IBuildingSearch>(BUILDING_SEARCH_TEXT);
  const [
    searchBuildingBlocks,
    { data: resultBuildingBlocks, loading: loadingBuildingBlocks },
  ] = useLazyQuery<IBuildingBlockSearch>(BLOCK_SEARCH_TEXT);
  const [
    searchBuildingBlockUnits,
    { data: resultBuildingBlockUnits, loading: loadingBuildingBlockUnits },
  ] = useLazyQuery<IBuildingBlockUnitSearch>(UNIT_SEARCH_TEXT);

  // SELECTED
  const [selectedBuilding, setSelectedBuilding] = useState<IBuilding>(null);
  const [selectedBuildingBlock, setSelectedBuildingBlock] =
    useState<IBuildingBlock>(null);
  const [selectedBuildingBlockUnit, setSelectedBuildingBlockUnit] =
    useState<IBuildingBlockUnit>(null);
  const [mapZoomLevel, setMapZoomLevel] = useState(3);
  const [searchProperties, setSearchProperties] =
    useState<SearchPropertiesProps>({
      unit: SIZE_UNIT.IN_METERS,
      min: 0,
      max: 100,
    });

  useEffect(() => {
    if (resultBuildings) {
      setBuildings(resultBuildings[SEARCH_TEXT_ADDRESS_KEY]);
    }
  }, [resultBuildings]);

  useEffect(() => {
    if (selectedBuilding && resultBuildingBlocks) {
      const newBuildingBlock = resultBuildingBlocks[BLOCK_SEARCH_KEY];
      setSelectedBuildingBlock(newBuildingBlock);
      setSelectedBuildingBlockUnit(null);
      setBuildingBlockUnits(
        newBuildingBlock.unit_ids.reduce((acc, val, idx) => {
          return acc.concat({
            _id: val,
            name: newBuildingBlock.unit_names[idx],
          });
        }, [])
      );
    }
  }, [
    selectedBuilding,
    setSelectedBuildingBlock,
    setSelectedBuildingBlockUnit,
    resultBuildingBlocks,
    setBuildingBlockUnits,
  ]);

  useEffect(() => {
    if (resultBuildingBlockUnits) {
      setSelectedBuildingBlockUnit(resultBuildingBlockUnits);
    }
  }, [resultBuildingBlockUnits]);

  const wrapped: WrappedProps = {
    selectedBuilding,
    selectedBuildingBlock,
    selectedBuildingBlockUnit,
    setSelectedBuilding,
    setSelectedBuildingBlock,
    setSelectedBuildingBlockUnit,
    mapZoomLevel,
    setMapZoomLevel,
    searchProperties,
    setSearchProperties,
    searchBuildings,
    resultBuildings,
    loadingBuildings,
    searchBuildingBlocks,
    resultBuildingBlocks,
    loadingBuildingBlocks,
    searchBuildingBlockUnits,
    resultBuildingBlockUnits,
    loadingBuildingBlockUnits,
    buildings,
    buildingBlocks,
    setBuildingBlocks,
    buildingBlockUnits,
    setBuildingBlockUnits,
  };

  return (
    <AddressSearchContext.Provider value={wrapped}>
      {children}
    </AddressSearchContext.Provider>
  );
};
