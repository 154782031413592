import { Box, Typography, useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignItems: "center",
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        display: "flex",
        height: "30px",
        justifyContent: "center",
      }}
    >
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <a href="https://xai.land/" style={{ color: "inherit" }}>
          XAI Land
        </a>{" "}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
};

export default Footer;
