import { useTheme } from "@mui/material";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";
import formatPriceUnit from "../../../utils/formatPriceUnit";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface DataSet {
  label: string | number;
  data: number;
}

interface Props {
  datasets: DataSet[];
}

export default function LineBarChart({ datasets }: Props) {
  const labels = datasets.map(dataset => dataset.label);
  const theme = useTheme();

  const options: ChartOptions<"line"> = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          callback: function (value: number) {
            return `${value}만`;
          },
          color: theme.palette.primary.dark,
        },
      },
    },
  };

  const chartData: ChartData<"line"> = {
    labels,
    datasets: [
      {
        type: "line",
        label: "금액(만원)",
        data: datasets.map(dataset =>
          formatPriceUnit({
            price: Number(dataset.data),
            unit: "만",
            decimal: 0,
          })
        ),
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
      },
    ],
  };

  return <Line options={options} data={chartData} />;
}
