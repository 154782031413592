import { Outlet } from "react-router-dom";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

import "../styles/Layout.css";
import { routes } from "../routes";
import { useRealmApp } from "../RealmApp";

import NavBar from "./NavBar";
import Footer from "./Footer";
import { AddressSearchProvider } from "./AddressSearch/context";

const Layout: React.FC = () => {
  const theme = useTheme();
  const { isLoadingApp } = useRealmApp();

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {isLoadingApp && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            position: "absolute",
            zIndex: 90,
            width: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.grey[500],
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              opacity: 0.9,
              position: "absolute",
              zIndex: 91,
            }}
          />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 92,
              width: 100,
            }}
          >
            <CircularProgress size={30} />
            <Typography variant="body1" mt={0.5}>
              로딩중
            </Typography>
          </Box>
        </Box>
      )}
      <NavBar />

      <main style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <AddressSearchProvider resultUrl={routes.root}>
            <Outlet />
          </AddressSearchProvider>
        </div>
      </main>

      <footer>
        <Footer />
      </footer>
    </Box>
  );
};

export default Layout;
