import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";

import { IBuildingBlock, IBuildingBlockSearch } from "../../graphql/types";

import { BuildingBlock } from "../types";

interface BlockSearchBoxProps {
  searchBuildingBlocks: LazyQueryExecFunction<
    IBuildingBlockSearch,
    OperationVariables
  >;
  loadingBuildingBlocks: boolean;
  buildingBlocks: BuildingBlock[];
  selectedBuildingBlock: IBuildingBlock;
  disabled: boolean;
}

const BlockSearchBox: React.FC<BlockSearchBoxProps> = ({
  searchBuildingBlocks,
  loadingBuildingBlocks,
  buildingBlocks,
  selectedBuildingBlock,
  disabled,
}) => {
  const theme = useTheme();

  const handleBlockChange = (e: SelectChangeEvent<string>) => {
    searchBuildingBlocks({ variables: { block_id: e.target.value } });
  };

  return (
    <Box sx={{ position: "relative" }} width={1}>
      {loadingBuildingBlocks ? (
        <LinearProgress variant="indeterminate" />
      ) : (
        <Box sx={{ height: "4px" }} />
      )}
      <FormControl
        fullWidth
        disabled={disabled}
        sx={{
          backgroundColor: disabled ? theme.palette.grey[200] : "initial",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {!selectedBuildingBlock && (
          <InputLabel shrink={false}>
            <Typography
              variant="subtitle2"
              sx={{ color: theme.palette.text.secondary }}
            >
              동 (Block)
            </Typography>
          </InputLabel>
        )}
        <Select
          onChange={handleBlockChange}
          disabled={disabled}
          value={selectedBuildingBlock?._id ?? ""}
          MenuProps={{
            PaperProps: { sx: { maxHeight: 250 } },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          sx={{
            "&.MuiInputBase-root": {
              borderRadius: 0,
            },
            "&.MuiOutlinedInput-root:hover": {
              fieldset: {
                borderColor: theme.palette.grey[200],
              },
            },
            fieldset: {
              "&.MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.grey[200]} !important`,
              },
            },
          }}
        >
          {buildingBlocks.map(buildingBlock => {
            return (
              <MenuItem key={buildingBlock._id} value={buildingBlock._id}>
                <Typography variant="subtitle2">
                  {buildingBlock.name}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BlockSearchBox;
