export enum USER_TYPE {
  NORMAL = "normal",
  ADMIN = "admin",
}

export enum SETTING {
  PROFILE = "Profile",
  ACCOUNT = "Account",
  DASHBOARD = "Dashboard",
  LOGOUT = "Logout",
}
